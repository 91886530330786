.current-client-history {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.assigned-client-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.client-history-item {
    background: #fff;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    margin-bottom: 4px;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05);

    position: relative; 
    transition: all 0.2s ease;

    & a {
        padding: 4px 8px;
        display: grid;
        text-decoration: none;
        color: inherit;
        grid-template-columns: 48px 1fr 100px;
        gap: 8px;
        align-items: center;
        
        /* Leave room for a check mark */
        margin-right: 8px;
    }
}

.client-history-item:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.client-logo {
    width: 48px; /* Adjust size as needed */
    height: 48px;
    flex-shrink: 0; /* Prevents logo from shrinking */
    border-radius: 4px;
    background-color: var(--neutrals-n5); /* Placeholder background */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    & img {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.client-logo:has(img) {
    background-color: var(--neutrals-white);;
}

.client-content {
    flex: 1;
    min-width: 0; /* Prevents text overflow issues */
}

.client-history-item.active {
    background-color: var(--neutrals-n1);
    border-left: 4px solid var(--primary-p3);

    &  .client-content {
        margin-left: -4px; /* Compensate for the border */
    }
}

.client-history-item.active::before {
    content: "✓";
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--primary-p3);
}

.switch-client__assigned {
    display: flex;
    justify-content: space-between;
    align-items: center;
}