.modal {
    --modal-footer-height: 87px;
    --modal-body-height: 70vh;
}

.modal-dynamic .modal-body {
    overflow-x: hidden;    
}

.modal-dynamic .modal-body.scrollable {
    overflow-y: auto;    
}
.modal-dynamic .modal-body.non-scrollable {
    /* Hide the scroll bars */
    overflow-y: hidden;
    /* Enable the dropdowns to show on top */
    overflow: visible;
}
/**
 * Modal Widths
 */
.modal-dialog.modal-dynamic {
    min-width: 40vw;
}

.modal-dialog.modal-dynamic-xs {
    min-width: 10vw;
}

.modal-dialog.modal-dynamic-sm {
    width: 50vw;
}

.modal-dialog.modal-dynamic-md {
    width: 60vw;
}

.modal-dialog.modal-dynamic-lg {
    width: 70vw;
}

.modal-dialog.modal-dynamic-xl {
    width: 80vw;
}

/**
 * END Modal Widths
 */

.modal-footer {
    position: relative;
}

.modal-footer .confirmation-overlay {
    background-color: var(--color-white);
    height: 100%;
    left: 0;
    position: absolute;
    text-align: left;
    top: 0;
}

.confirmation-overlay .alert {
    margin-bottom: 0;
    padding: 5px;
}

.scheme-terrene {
    --header-border:none;
}

.basic-modal .modal-dialog {
    border-radius: 12px;
}

.modal.basic-modal[data-display-mode="PANEL"] .modal-dialog {
    border-radius: 0;
}

.basic-modal .modal-header,
.basic-modal .modal-body,
.basic-modal .modal-footer {
    --modal-header-bottom-margin: 24px;

    padding: 0;  /* Padding comes from .modal-content */
}

.basic-modal .modal-header {
    background-color: var(--neutrals-white);
    margin-bottom: var(--modal-header-bottom-margin);
}

.basic-modal .modal-footer {
    background-color: var(--neutrals-white);
    margin-top: 24px;
}

.basic-modal .modal-content {
    background-color: var(--neutrals-white);
    border-radius: 12px;
    box-shadow: 0px 24px 60px rgba(8, 16, 37, 0.12);
    padding: 32px;
}

.basic-modal--message {
    --modal-bg-color: var(--color-white);
    --modal-border-color: var(--color-gray-big-stone);
    
}

.basic-modal--message .button {
    --modal-button-bg-color: var(--btn-background-color);
    --modal-button-border-color: var(--btn-border-color);
    --modal-button-color: var(--btn-text-color);
}

.basic-modal--message .btn {
    --modal-button-bg-color: var(--color-white);
    --modal-button-border-color: var(--color-gray-big-stone);
    --modal-button-color: var(--color-gray-big-stone);
}


.basic-modal--warning,
.basic-modal--danger {
    --modal-border-color: var(--color-danger);  
}

.basic-modal--warning.modal,
.basic-modal--danger.modal {
    /* Center it vertically */
    top: 25% 
}

.basic-modal--message.basic-modal--warning .button--tertiary,
.basic-modal--message.basic-modal--danger .button--tertiary {
    --modal-button-color: var(--color-default);
}


/* Schemes  */
.flat .modal-content {
    background-color: transparent;
}

.flat .modal-header {
    background: var(--color-gray-big-stone);
    border-radius: 4px 4px 0 0;
    color: var(--color-white);
    border-bottom-width: 0px;
}

.flat .modal-header .close {
    color: var(--color-white);
}

.flat .modal-body,
.flat .modal-footer {
    background-color: var(--color-white);
    border: none;
}

.flat .modal-body {
    padding: 0;
}

.flat .save-button {
    background: #00bed6;
    border-radius: 2px;
    border-color: #00bed6;
    color: var(--color-white);
    padding: 2px 6px;
}

.scheme-umbra .modal-content {
    background-color: transparent;
}

.scheme-umbra .modal-header {
    background: var(--color-white-gainsboro);
    border-radius: 4px 4px 0 0;
    color: var(--color-gray-big-stone);
    border-bottom-width: 0px;
}

.scheme-umbra .modal-body,
.scheme-umbra .modal-footer {
    background-color: var(--color-white);
    border: none;
}

.scheme-umbra .modal-body {
    padding: 0 15px;
}

.modal-header .close {
    color: var(--color-gray-big-stone);
    opacity: 1;
}

.scheme-umbra .search-row {
    background-color: var(--color-gray-chateau);
}

.scheme-umbra .search-row {
    padding: 15px 30px;
}

.scheme-umbra .search-row .form-group {
    margin-bottom: 0;
}

.scheme-umbra .search-row .form-control {
    font-size: 18px;
    height: 48px;
}

.scheme-umbra .search-row .basic-filter-wrapper a.clear {
    top: 21%;
}

@media (min-width: 1360px) {
    .scheme-umbra .modal-dialog.modal-dynamic-xl {
        width: 1200px;
    }
}

.scheme-umbra .modal-content-holder > .row > div {
    padding: 0;
}

.scheme-umbra .chosen-container-single .chosen-single {
    padding-top: 0;
    padding-bottom: 0;
}

.scheme-albus .save-button {
    background: #00bed6;
    border-color: #00bed6;
    color: var(--color-white);

}
.scheme-albus .btn.save-button {    
    border-radius: 2px;    
    padding: 2px 6px;
}

.scheme-albus .modal-footer .btn-primary {
    background-color: var(--color-blue-robin-egg);
    border-color: var(--color-blue-robin-egg);
    color: var(--color-white);
}

.scheme-umbra .modal-footer .btn-primary[disabled] {
    opacity: 0.5;
}

.basic-modal--message .modal-content {
    padding: 8px;

}

.basic-modal--message .modal-header {
    --modal-header-bottom-margin: 8px;
}

.basic-modal--message .modal-footer .save-button,
.basic-modal--message .modal-footer .cancel-button {    
    background-color: var(--modal-button-bg-color);
    border-color: var(--modal-button-border-color);
    color: var(--modal-button-color);
}

.basic-modal--message .modal-footer .save-button.button--destructive[disabled] {
    color: var(--modal-button-border-color);
}

.basic-modal--message .modal-footer .btn  {
    border-radius: 2px;
    padding: 3px 15px;
    box-shadow: none;
}

.modal-footer .scheme-albus.btn,
.modal-footer .scheme-umbra.btn {
    border-radius: 2px;
    border: none;
    padding: 3px 15px;
}

.scheme-albus .modal-header {
    display: none;
}

.scheme-albus .modal-footer {
    border-top: none;
}

.scheme-albus .cancel-button {
    background-color: var(--color-gray-chateau);
    border: none;
    color: var(--color-white);
}


.basic-modal--message .modal-body,
.basic-modal--message .modal-footer,
.basic-modal--message .modal-header {
    background-color: var(--modal-bg-color);
    padding: 0px;
}

.basic-modal--message .modal-content {
    border-color: var(--modal-border-color);
    border-radius: 0;
    border-top-width: 10px;
}

.basic-modal--message .modal-header {
    border-bottom: none;
    padding: 10px 5px 0px 5px;
}

.basic-modal--message .close {
    color: var(--color-gray-big-stone);
    opacity: 100;
    text-shadow: none;
}

.basic-modal--message.modal-body .modal-body {
    color: var(--color-gray-big-stone);
    padding: 0px;
}

.basic-modal--message .modal-title {
    display: none;
}

.basic-modal--message .modal-footer {
    border-top: none;
    padding: 0px 10px 10px 10px;
}

.basic-modal--message .modal-body.scrollable {
    overflow-x: hidden;
}

.basic-modal--message {
    bottom: revert;
    top: 50px;
}

.scheme-terrene .modal-header {
    border-bottom: none;
}

.scheme-terrene .modal-footer {
    border-top: none;
}

.scheme-terrene .file-upload {
    padding: 15px;
}

/* End Schemes  */

.over-modal-z {
    z-index: 2;
}

.basic-modal-footer {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr minmax(min-content, max-content);
    min-width: 1px; /* footer from going to 0 width which will make the buttons go outside the modal */
}


.scheme-umbra .file-upload input[type='file'] {
    height: 36px;
}

/* Remove the top border when a table is used in modal */
.modal-content-holder .table-striped tr:nth-child(1) td {
    border-top: none;
}


.modal[data-display-mode="MODAL"] .modal-dialog[data-height="md"] .modal-body {
    max-height: var(--modal-body-height);
}

.modal-dialog[data-height="lg"] .modal-body {
    height: min(900px, var(--modal-body-height));
}

.basic-modal__close-icon i {
    color: var(--neutrals-n7);
    font-size: 20px
}



/* Older Modals still need this padding */

.scheme-umbra.basic-modal .modal-content {
    padding: 0;
    background-color: #FFFFFF;
}

.scheme-umbra.basic-modal .modal-header,
.scheme-umbra.basic-modal .modal-footer {
    padding: 15px;
}

.scheme-umbra.basic-modal .modal-body {
    padding: 0 15px;
}

.back-link {
    color: var(--neutrals-n10);
}

.modal.basic-modal[data-display-mode="PANEL"] {
    --modal-footer-height: 73px;
    --panel-modal-padding: 32px;
}

@media (max-height: 600px) {
    .modal.basic-modal[data-display-mode="PANEL"] {
        --modal-footer-height: 55px;
        --panel-modal-padding: 18px;
    }
}

.modal[data-display-mode="PANEL"] .modal-content-holder {
    height: 100%;
}

.modal[data-display-mode="PANEL"] .modal-content {
    height: 100vh;    
    border-radius: 0;
    border: none;
    box-shadow: none;
}

.modal[data-display-mode="PANEL"] .modal-dialog {
    --panel-width: 85vw;
    margin: 0;
    margin-left: calc(100% - var(--panel-width));
    width: var(--panel-width);
}

@media (max-width: 1295px) {
    .modal[data-display-mode="PANEL"] .modal-dialog {
        overflow-x: auto;
    }
}

.modal[data-display-mode="PANEL"] .modal-header {
    display: none;
}

.modal[data-display-mode="PANEL"] .modal-body {
    
    padding:  var(--panel-modal-padding) var(--panel-modal-padding) 8px;    
    height: calc(100% - var(--modal-footer-height));
    box-sizing: border-box;
    overflow-y: hidden;
}

.modal[data-display-mode="PANEL"] .modal-footer {
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    margin-top: 0;
}

.modal.basic-modal[data-display-mode="PANEL"] .modal-footer {
    padding: 8px var(--panel-modal-padding) var(--panel-modal-padding);
}

.modal.basic-modal[data-display-mode="PANEL"] .modal-button-slot button[data-dismiss="modal"] {
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
}

.modal.fade:not(.in)[data-fade-direction="right"] .modal-dialog {
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
}