
body {

    --terrene-label-weight: bold;
    
    --terrene-bottom-border-color: var(--color-white-gainsboro);
    --terrene-field-height: 40px;
    --terrene-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
    --terrene-font-weight: 400;
    --terrene-font-size: 14px;
    --terrene-font-family: Roboto, sans-serif;
    --terrene-font-color: var(--color-gray-night-rider);    

    --terrene-error-color: var(--red-r4);
    --terrene-active-color: var(--color-green-lotame);
    --terrene-inactive-color: var(--neutrals-n8);

    font-size: var(--terrene-font-size);
    font-family: var(--terrene-font-family);

    --brand-font-size: var(--terrene-font-size);


    --terrene-h-14-font-size: 14px;

    --terrene-h-18-font-size: 18px;
    --terrene-h-18-font-weight: var(--font-weight-semibold);
}

i {
    font-style: normal;
}

.btn.active {
    box-shadow: none;
}

.alert--danger {
    background-color: var(--color-danger);
    color: var(--color-white);
    margin-bottom: 0;
}

.bg-danger,
.bg--destructive, 
.bg--destructive a.icon-action-link{
    color: var(--red-r3);
}

.bg-danger .text-danger {
    color: var(--color-white);
}


.friendly-number__value {
    font-weight: var(--terrene-label-weight);
}