@import 'Terrene.css';

.select {
    --select-box-shadow: var(--terrene-box-shadow);
    --fc-border-color: var(--color-white-gainsboro);
    --select-max-width: 100%;
    max-width: var(--select-max-width);
}

.select .select2-container--terrene {
    --select-border-width: 2px;
}

.select__label {
    font-weight: var(--terrene-label-weight);
}

.select .select2-container--terrene {
    background-color: #ffffff;
    box-shadow: var(--select-box-shadow);    
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    
}

.select .select2-container--open :is(.select2-dropdown--below,.select2-dropdown--above) {
    border: none;
    box-shadow: var(--terrene-box-shadow);
}

.select .select2-search__field {
    border: none;
    position: absolute;
    top: -28px;
    left: 0;
}

.select .select2-search.select2-search--dropdown {
    border-bottom: solid 1px var(--color-white-gainsboro);
}

.select .select2-container--terrene .select2-results__option {
    border-bottom: solid 1px var(--color-white-gainsboro);
    font-weight: var(--terrene-label-weight);
    padding: 12px;
}

.select .select2-container--terrene .select2-results__option--highlighted {
    background-color: var(--color-white-gainsboro);

    /* Cover the border above */
    margin-top: calc(var(--select-border-width) * -1);
}

.select .select2-container--terrene .select2-results__option:last-child .select2-results__text {
    border-bottom: none;
}

.select .select2-container--terrene .select2-selection__rendered,
.select .select2-container--terrene .select2-search--inline {
    box-sizing: border-box;
    margin-bottom: 0;
    padding-left: 0;
    width: 100%;    
    
}

.select .select2-container--terrene .select2-search--inline {
    list-style-image: url('svgs/search-line.svg');
    margin-left: 1.5em;
    font-size: 100%;
}
.select .select2-container--terrene .select2-search--inline::marker {
    font-size: 32px;
}

.select .select2-container--terrene .select2-selection__rendered {

    align-items: baseline;
    color: var(--color-gray-chateau);
    display: grid;
    grid-template-columns: 50px 1fr;
    justify-items: start;
    padding-left: 5px;
}

.select .select2-container--terrene .select2-selection__rendered:after {
    background-image: url('svgs/chevron-down.svg');
    background-position: top;
    background-repeat: no-repeat;
    bottom: 2px;
    content: '';
    display: inline-block;
    height: 11px;
    position: absolute;
    right: 10px;
    transform: translateY(-50%);
    width: 11px;
}

.select .select2-container--terrene.select2-container--open .select2-selection__rendered:after {
    background-image: url('svgs/chevron-up.svg');
}

.select .select2-container--terrene.select2-container .select2-selection--single,
.select .select2-container--terrene.select2-container .select2-selection--multiple {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    height: var(--terrene-field-height);
}

.select .select2-container--terrene.select2-container--open .select2-selection--single,
.select .select2-container--terrene.select2-container--open .select2-selection--multiple,
.select .select2-container--terrene.select2-container--selected{
    border-bottom: var(--select-border-width) solid var(--terrene-active-color);
}

.select .select2-container--terrene .select2-selection--single,
.select .select2-container--terrene .select2-selection--multiple {
    border-bottom: 1px solid var(--color-white-gainsboro);
    border-radius: 5px 5px 0 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select .select2-container--terrene .select2-selection__placeholder {
    font-weight: var(--terrene-label-weight);
    color: var(--color-gray-chateau);
}

.select .select2-container--terrene .select2-results__text:before {
    box-sizing: border-box;
    color: #999;
    content: '\f096';
    font-family: fontAwesome;
    font-size: 13px;
    height: 25px;
    padding-right: 10px;
    width: 25px;
}

.select .select2-results__option[aria-selected='true'] .select2-results__text:before {
    color: var(--color-primary);
    content: '\f14a';
}

.select .select2-container--terrene .select2-selection__choice {
    background-color: var(--color-gray-big-stone);
    border-radius: 16px;
    color: var(--color-white);
    display: grid;
    font-size: 14px;
    font-weight: var(--terrene-label-weight);
    grid-gap: 5px;
    grid-template-columns: 15px 15px;
    list-style: none;
    padding: 0px 5px;
    z-index: 10;
}

/**
* Only show one of the selected choices
*/
.select .select2-container--terrene .select2-selection__choice:not(:first-of-type) {
    display: none;
}

.select .select2-container--terrene .select2-selection__choice__remove {
    order: 2
}

.select .select2-container--terrene .select2-selection__choice__count {
    order: 1
}

.select .select2-container--terrene .select2-selection__clear {
    position: absolute;
    right: 25px;
    top: 25%;
}

/** Don't show the clear button for multi select */
.select.-multiple .select2-container--terrene .select2-selection__clear {
    display: none;
}

.select.-multiple .select2-selection__clear {
    color: var(--color-white);
    font-size: 12px;
    left: 50px;
    position: absolute;
    top: calc(var(--terrene-field-height) / 4 + 2px);
    width: 10px;    
}

.select.-multiple .select2-container .select2-search--inline .select2-search__field:disabled {
    background-color: unset;
}

.select .select2-results > .select2-results__options {
    max-height: 275px;
    overflow-y: auto;
    scrollbar-gutter: auto;
}

.select .select2-search__field:focus-visible {
    outline: none;
}

.select .select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
}

.select .select2-results__option:empty {
    display: none;
}

/* bug fix to hide the lotacon-remove that Form Builder adds to invalid elements */
.legacy-select-content span.fb-validation.lotacon.lotacon-remove {
    display: none;
}

/* bug fix to hide the input field that was obscuring some labels */ 
.select2-container .select2-search--inline input.select2-search__field {
    display: none;
}