.switch-client {
    --clientSearchBoxWidth: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#clientSearchBox {
    width: var(--clientSearchBoxWidth);
}

.menu-scheme-sinistrom #all-clients {

    & .tt-dropdown-menu {
        border: none;
        box-shadow: 2px -2px 8px 4px rgba(0, 0, 0, 0.25);
        width: calc(var(--clientSearchBoxWidth));
        overflow-y: auto;
        max-height: 90vh;
        margin-left: 0px;
        margin-top: 10px;
    }

    & .typeAheadName {
        max-width: unset;
    }

    & .twitter-typeahead {
        width: 100%;
    }
}

.menu-scheme-sinistrom  .switch-client {
    .typeAheadResult {
        display: block;
    }

    & .typeAheadResultContent {
        display: grid;
        grid-template-columns: 48px 1fr 100px;
        gap: 8px;
        align-items: center;
    }

    & .client-logo {
        font-size: smaller;
    }
}