@import 'Terrene.css';
.button {
    --btn-border-radius: 4px;
    --btn-box-shadow: none;
    --btn-disabled-text-color: var(--terrene-inactive-color);
    --btn-padding: 11px 17px;
    --btn-font-size: 14px;


    background-image: none;
    border-radius: var(--btn-border-radius);
    border: 1px solid transparent;
    box-shadow: var(--btn-box-shadow);        
    cursor: pointer;
    font-size: var(--btn-font-size);
    font-weight: 500;        
    line-height: 16px;
    outline: none;
    padding: var(--btn-padding);
    user-select: none;
    white-space: nowrap;
}

a.button--link {
    padding: 0;
}

a.button:hover {
    text-decoration: none;
}

.button.button--link {
    border:none;
    padding: 0;
}

.button.button--link:hover:enabled {
    box-shadow: none;
}

.button .lotacon {
    line-height: 16px;
    margin-right: 5px;
    top: -1px;
}

.button:hover:enabled {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    outline: none;
}

.button {
    background-color: var(--btn-background-color);
    border-color: var(--btn-border-color);
    color: var(--btn-text-color);
    margin-bottom: 1px;
}

.button[data-size="sm"] {
    --btn-padding: 5px 10px;
    --btn-font-size: 12px;
}

.button[data-size="xs"] {
    --btn-padding: 2px 5px;
    --btn-font-size: 12px;
    & .lotacon {
        line-height: 12px;
        font-size: 14px;
        margin-right: 3px;
    }
}

.button .caret {
    background-position: top;
    --caret-svg: url('svgs/chevron-down.svg');
    background-image: var(--caret-svg);
    background-repeat: no-repeat;
    
    /*
    * Change the color of the caret 
    */
    background-color: var(--btn-text-color);
    /* Chrome, still requires prefix in 2022 */
    -webkit-mask: var(--caret-svg);
    -webkit-mask-repeat: no-repeat;

    /* Firefox and Safari */
    mask: var(--caret-svg);
    mask-repeat: no-repeat;

    /* Override the caret defined by the border */
    border: none;
    margin-left: 0;
    height: 11px;
    width: 11px;
}

.button:active {
    box-shadow: none;
}

.button[disabled],
a.button.disabled,
a.button.disabled:hover {
    border-color: var(--color-gray-chateau);
    color: var(--btn-disabled-text-color);
    opacity: 1;
}

a.button.disabled {
    cursor:not-allowed;
}
a.button.disabled:active {
    pointer-events: none;
}

.button[disabled],
.button[disabled] i {
    cursor: not-allowed;
}

.button--primary {
    --btn-text-color: var(--color-white);
    --btn-background-color: var(--color-primary);
}

.button--primary.button--destructive {
    --btn-background-color: var(--color-danger);
    --btn-border-color: var(--color-danger);
}

.button--primary.button--destructive:hover:enabled {
    background-color: var(--color-danger-lighter);
    border-color: var(--color-danger-lighter);
}

.button--primary.button--destructive:active:enabled {
    background-color: var(--color-danger-darker);
    border-color: var(--color-danger-darker);
}

.button--primary:hover:enabled,
a.button--primary:hover {
    background-color: var(--color-primary-lighter);
    border-color: var(--color-primary-lighter);
    outline: none;
}

.button--primary:active:enabled {
    background-color: var(--color-primary-darker);
    --btn-border-color: var(--color-primary-darker);
    box-shadow: none;
    outline: none;
}

.button--primary[disabled],
.button--primary.disabled {
    --btn-background-color: var(--color-white-gainsboro);
    border-color: var(--btn-background-color);
}

.button--secondary {
    --btn-background-color: var(--color-white);
    --btn-border-color: var(--color-primary);
    --btn-text-color: var(--color-primary);
}

.button--secondary.cancel-button {
    --btn-border-color: var(--neutrals-n7);
    --btn-text-color: var(--neutrals-n10);
}

.button--secondary.button--destructive {
    --btn-border-color: var(--color-danger);
    --btn-text-color: var(--color-danger);
}

.button--secondary.button--destructive:hover:enabled,
a.button--secondary.button--destructive:hover {
    border-color: var(--color-danger-lighter);
    color: var(--color-danger-lighter);
}

.button--secondary.button--destructive:active:enabled {
    border-color: var(--color-danger-darker);
    color: var(--color-danger-darker);
}

.button--secondary:hover:enabled,
a.button--secondary:hover {
    border-color: var(--color-primary-lighter);
    color: var(--color-primary-lighter);
    outline: none;
}

.button--secondary:active:enabled {
    border-color: var(--color-primary-darker);
    color: var(--color-primary-darker);
    outline: none;
}

.button--secondary[disabled] {
    --btn-background-color: var(--color-white);
}

.button--tertiary {
    --btn-background-color: transparent;
    --btn-border-color: transparent;
    --btn-text-color: var(--color-primary);
    outline: none;
}

.button--tertiary.button--destructive {
    --btn-text-color: var(--color-danger);
    --btn-border-color: var(--btn-background-color);
}

.button--tertiary.button--destructive:hover:enabled,
a.button--tertiary.button--destructive:hover {
    color: var(--color-danger-lighter);
}

.button--tertiary.button--destructive:active:enabled {
    color: var(--color-danger-darker);
}

.button--tertiary:hover:enabled,
a.button--tertiary:hover {
    color: var(--color-primary-lighter);
    box-shadow: none;
    outline: none;
}

.button--tertiary:active:enabled {
    color: var(--color-primary-darker);
    outline: none;
}

.button--tertiary[disabled] {
    border-color: var(--btn-background-color);
}


.button--link {
    --btn-background-color: transparent;
    --btn-border-color: transparent;
    --btn-text-color: var(--color-primary);
    outline: none;
    box-shadow: none;
}

.button--link:hover:enabled {
    box-shadow: none;
}

.btn-group.open .button--link.dropdown-toggle {
    box-shadow: none;
}

.button--link.button--destructive {
    --btn-text-color: var(--color-danger);
    --btn-border-color: var(--btn-background-color);
}

.button--link.button--destructive:hover:enabled,
a.button--link.button--destructive:hover {
    color: var(--color-danger-lighter);
}

.button--link.button--destructive:active:enabled {
    color: var(--color-danger-darker);
}

.button--link:hover:enabled,
a.button--link:hover {
    color: var(--color-primary-lighter);
    box-shadow: none;
    outline: none;
}

.button--link:active:enabled {
    color: var(--color-primary-darker);
    outline: none;
}

.button--link[disabled] {
    border-color: var(--btn-background-color);
}


.btn-group .button + .button,
.btn-group .button + .btn-group,
.btn-group .btn-group + .button,
.btn-group .btn-group + .btn-group {
    margin-left: -5px;
}

.button--split {
    --btn-background-color: var(--color-white);    
    border: none;
    height: var(--terrene-field-height);
}

.button--split .dropdown-toggle {
    padding: 11px 5px;
}

.button--split .dropdown-toggle.btn-sm {
    padding: 5px 10px;
}

.button--split .dropdown-menu {
    width: 100%;
}